exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blastomere-biopsy-micropipette-js": () => import("./../../../src/pages/blastomere-biopsy-micropipette.js" /* webpackChunkName: "component---src-pages-blastomere-biopsy-micropipette-js" */),
  "component---src-pages-blog-category-blastomere-biopsy-micropipette-js": () => import("./../../../src/pages/blog/category/blastomere-biopsy-micropipette.js" /* webpackChunkName: "component---src-pages-blog-category-blastomere-biopsy-micropipette-js" */),
  "component---src-pages-blog-category-frozen-embryo-transfer-js": () => import("./../../../src/pages/blog/category/frozen-embryo-transfer.js" /* webpackChunkName: "component---src-pages-blog-category-frozen-embryo-transfer-js" */),
  "component---src-pages-blog-category-gamete-intrafallopian-transfer-js": () => import("./../../../src/pages/blog/category/gamete-intrafallopian-transfer.js" /* webpackChunkName: "component---src-pages-blog-category-gamete-intrafallopian-transfer-js" */),
  "component---src-pages-blog-category-history-js": () => import("./../../../src/pages/blog/category/history.js" /* webpackChunkName: "component---src-pages-blog-category-history-js" */),
  "component---src-pages-blog-category-holding-micropipette-js": () => import("./../../../src/pages/blog/category/holding-micropipette.js" /* webpackChunkName: "component---src-pages-blog-category-holding-micropipette-js" */),
  "component---src-pages-blog-category-in-vitro-fertilization-js": () => import("./../../../src/pages/blog/category/in-vitro-fertilization.js" /* webpackChunkName: "component---src-pages-blog-category-in-vitro-fertilization-js" */),
  "component---src-pages-blog-category-injection-micropipette-js": () => import("./../../../src/pages/blog/category/injection-micropipette.js" /* webpackChunkName: "component---src-pages-blog-category-injection-micropipette-js" */),
  "component---src-pages-blog-category-intracytoplasmic-sperm-injection-js": () => import("./../../../src/pages/blog/category/intracytoplasmic-sperm-injection.js" /* webpackChunkName: "component---src-pages-blog-category-intracytoplasmic-sperm-injection-js" */),
  "component---src-pages-blog-category-intrauterine-insemination-js": () => import("./../../../src/pages/blog/category/intrauterine-insemination.js" /* webpackChunkName: "component---src-pages-blog-category-intrauterine-insemination-js" */),
  "component---src-pages-blog-category-js": () => import("./../../../src/pages/blog/category.js" /* webpackChunkName: "component---src-pages-blog-category-js" */),
  "component---src-pages-blog-category-more-interesting-topics-js": () => import("./../../../src/pages/blog/category/more-interesting-topics.js" /* webpackChunkName: "component---src-pages-blog-category-more-interesting-topics-js" */),
  "component---src-pages-blog-category-polar-body-biopsy-micropipette-js": () => import("./../../../src/pages/blog/category/polar-body-biopsy-micropipette.js" /* webpackChunkName: "component---src-pages-blog-category-polar-body-biopsy-micropipette-js" */),
  "component---src-pages-blog-category-trophectoderm-biopsy-micropipette-bevelled-js": () => import("./../../../src/pages/blog/category/trophectoderm-biopsy-micropipette-bevelled.js" /* webpackChunkName: "component---src-pages-blog-category-trophectoderm-biopsy-micropipette-bevelled-js" */),
  "component---src-pages-blog-category-trophectoderm-biopsy-micropipette-flat-js": () => import("./../../../src/pages/blog/category/trophectoderm-biopsy-micropipette-flat.js" /* webpackChunkName: "component---src-pages-blog-category-trophectoderm-biopsy-micropipette-flat-js" */),
  "component---src-pages-blog-category-zygote-intrafallopian-transfer-js": () => import("./../../../src/pages/blog/category/zygote-intrafallopian-transfer.js" /* webpackChunkName: "component---src-pages-blog-category-zygote-intrafallopian-transfer-js" */),
  "component---src-pages-blog-comp-1-js": () => import("./../../../src/pages/blogComp1.js" /* webpackChunkName: "component---src-pages-blog-comp-1-js" */),
  "component---src-pages-blog-comp-1-x-js": () => import("./../../../src/pages/blogComp1x.js" /* webpackChunkName: "component---src-pages-blog-comp-1-x-js" */),
  "component---src-pages-blog-comp-2-js": () => import("./../../../src/pages/blogComp2.js" /* webpackChunkName: "component---src-pages-blog-comp-2-js" */),
  "component---src-pages-blog-comp-2-x-js": () => import("./../../../src/pages/blogComp2x.js" /* webpackChunkName: "component---src-pages-blog-comp-2-x-js" */),
  "component---src-pages-blog-comp-3-js": () => import("./../../../src/pages/blogComp3.js" /* webpackChunkName: "component---src-pages-blog-comp-3-js" */),
  "component---src-pages-blog-comp-4-js": () => import("./../../../src/pages/blogComp4.js" /* webpackChunkName: "component---src-pages-blog-comp-4-js" */),
  "component---src-pages-blog-comp-5-js": () => import("./../../../src/pages/blogComp5.js" /* webpackChunkName: "component---src-pages-blog-comp-5-js" */),
  "component---src-pages-blog-comp-6-js": () => import("./../../../src/pages/blogComp6.js" /* webpackChunkName: "component---src-pages-blog-comp-6-js" */),
  "component---src-pages-blog-comp-7-js": () => import("./../../../src/pages/blogComp7.js" /* webpackChunkName: "component---src-pages-blog-comp-7-js" */),
  "component---src-pages-blog-comp-8-js": () => import("./../../../src/pages/blogComp8.js" /* webpackChunkName: "component---src-pages-blog-comp-8-js" */),
  "component---src-pages-blog-comp-9-js": () => import("./../../../src/pages/blogComp9.js" /* webpackChunkName: "component---src-pages-blog-comp-9-js" */),
  "component---src-pages-blog-comp-tag-js": () => import("./../../../src/pages/blogCompTag.js" /* webpackChunkName: "component---src-pages-blog-comp-tag-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-mb-1-js": () => import("./../../../src/pages/blogMB1.js" /* webpackChunkName: "component---src-pages-blog-mb-1-js" */),
  "component---src-pages-blog-mb-10-js": () => import("./../../../src/pages/blogMB10.js" /* webpackChunkName: "component---src-pages-blog-mb-10-js" */),
  "component---src-pages-blog-mb-11-js": () => import("./../../../src/pages/blogMB11.js" /* webpackChunkName: "component---src-pages-blog-mb-11-js" */),
  "component---src-pages-blog-mb-12-js": () => import("./../../../src/pages/blogMB12.js" /* webpackChunkName: "component---src-pages-blog-mb-12-js" */),
  "component---src-pages-blog-mb-13-dynamic-js": () => import("./../../../src/pages/blogMB13Dynamic.js" /* webpackChunkName: "component---src-pages-blog-mb-13-dynamic-js" */),
  "component---src-pages-blog-mb-13-js": () => import("./../../../src/pages/blogMB13.js" /* webpackChunkName: "component---src-pages-blog-mb-13-js" */),
  "component---src-pages-blog-mb-2-js": () => import("./../../../src/pages/blogMB2.js" /* webpackChunkName: "component---src-pages-blog-mb-2-js" */),
  "component---src-pages-blog-mb-3-js": () => import("./../../../src/pages/blogMB3.js" /* webpackChunkName: "component---src-pages-blog-mb-3-js" */),
  "component---src-pages-blog-mb-4-js": () => import("./../../../src/pages/blogMB4.js" /* webpackChunkName: "component---src-pages-blog-mb-4-js" */),
  "component---src-pages-blog-mb-5-js": () => import("./../../../src/pages/blogMB5.js" /* webpackChunkName: "component---src-pages-blog-mb-5-js" */),
  "component---src-pages-blog-mb-6-js": () => import("./../../../src/pages/blogMB6.js" /* webpackChunkName: "component---src-pages-blog-mb-6-js" */),
  "component---src-pages-blog-mb-7-js": () => import("./../../../src/pages/blogMB7.js" /* webpackChunkName: "component---src-pages-blog-mb-7-js" */),
  "component---src-pages-blog-mb-8-js": () => import("./../../../src/pages/blogMB8.js" /* webpackChunkName: "component---src-pages-blog-mb-8-js" */),
  "component---src-pages-blog-mb-9-js": () => import("./../../../src/pages/blogMB9.js" /* webpackChunkName: "component---src-pages-blog-mb-9-js" */),
  "component---src-pages-comp-faq-js": () => import("./../../../src/pages/comp-faq.js" /* webpackChunkName: "component---src-pages-comp-faq-js" */),
  "component---src-pages-customer-support-js": () => import("./../../../src/pages/customer-support.js" /* webpackChunkName: "component---src-pages-customer-support-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-mb-10-js": () => import("./../../../src/pages/faqMB10.js" /* webpackChunkName: "component---src-pages-faq-mb-10-js" */),
  "component---src-pages-faq-mb-11-js": () => import("./../../../src/pages/faqMB11.js" /* webpackChunkName: "component---src-pages-faq-mb-11-js" */),
  "component---src-pages-faq-mb-12-js": () => import("./../../../src/pages/faqMB12.js" /* webpackChunkName: "component---src-pages-faq-mb-12-js" */),
  "component---src-pages-faq-mb-13-dynamic-js": () => import("./../../../src/pages/faqMB13Dynamic.js" /* webpackChunkName: "component---src-pages-faq-mb-13-dynamic-js" */),
  "component---src-pages-faq-mb-3-js": () => import("./../../../src/pages/faqMB3.js" /* webpackChunkName: "component---src-pages-faq-mb-3-js" */),
  "component---src-pages-faq-mb-4-js": () => import("./../../../src/pages/faqMB4.js" /* webpackChunkName: "component---src-pages-faq-mb-4-js" */),
  "component---src-pages-faq-mb-5-js": () => import("./../../../src/pages/faqMB5.js" /* webpackChunkName: "component---src-pages-faq-mb-5-js" */),
  "component---src-pages-faq-mb-6-js": () => import("./../../../src/pages/faqMB6.js" /* webpackChunkName: "component---src-pages-faq-mb-6-js" */),
  "component---src-pages-faq-mb-7-js": () => import("./../../../src/pages/faqMB7.js" /* webpackChunkName: "component---src-pages-faq-mb-7-js" */),
  "component---src-pages-faq-mb-8-js": () => import("./../../../src/pages/faqMB8.js" /* webpackChunkName: "component---src-pages-faq-mb-8-js" */),
  "component---src-pages-faq-mb-9-js": () => import("./../../../src/pages/faqMB9.js" /* webpackChunkName: "component---src-pages-faq-mb-9-js" */),
  "component---src-pages-frequently-asked-questions-category-blastomere-biopsy-micropipette-js": () => import("./../../../src/pages/frequently-asked-questions/category/blastomere-biopsy-micropipette.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-blastomere-biopsy-micropipette-js" */),
  "component---src-pages-frequently-asked-questions-category-frozen-embryo-transfer-js": () => import("./../../../src/pages/frequently-asked-questions/category/frozen-embryo-transfer.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-frozen-embryo-transfer-js" */),
  "component---src-pages-frequently-asked-questions-category-gamete-intrafallopian-transfer-js": () => import("./../../../src/pages/frequently-asked-questions/category/gamete-intrafallopian-transfer.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-gamete-intrafallopian-transfer-js" */),
  "component---src-pages-frequently-asked-questions-category-history-js": () => import("./../../../src/pages/frequently-asked-questions/category/history.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-history-js" */),
  "component---src-pages-frequently-asked-questions-category-holding-micropipette-js": () => import("./../../../src/pages/frequently-asked-questions/category/holding-micropipette.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-holding-micropipette-js" */),
  "component---src-pages-frequently-asked-questions-category-in-vitro-fertilization-js": () => import("./../../../src/pages/frequently-asked-questions/category/in-vitro-fertilization.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-in-vitro-fertilization-js" */),
  "component---src-pages-frequently-asked-questions-category-injection-micropipette-js": () => import("./../../../src/pages/frequently-asked-questions/category/injection-micropipette.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-injection-micropipette-js" */),
  "component---src-pages-frequently-asked-questions-category-intracytoplasmic-sperm-injection-js": () => import("./../../../src/pages/frequently-asked-questions/category/intracytoplasmic-sperm-injection.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-intracytoplasmic-sperm-injection-js" */),
  "component---src-pages-frequently-asked-questions-category-intrauterine-insemination-js": () => import("./../../../src/pages/frequently-asked-questions/category/intrauterine-insemination.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-intrauterine-insemination-js" */),
  "component---src-pages-frequently-asked-questions-category-js": () => import("./../../../src/pages/frequently-asked-questions/category.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-js" */),
  "component---src-pages-frequently-asked-questions-category-more-interesting-topics-js": () => import("./../../../src/pages/frequently-asked-questions/category/more-interesting-topics.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-more-interesting-topics-js" */),
  "component---src-pages-frequently-asked-questions-category-polar-body-biopsy-micropipette-js": () => import("./../../../src/pages/frequently-asked-questions/category/polar-body-biopsy-micropipette.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-polar-body-biopsy-micropipette-js" */),
  "component---src-pages-frequently-asked-questions-category-trophectoderm-biopsy-micropipette-bevelled-js": () => import("./../../../src/pages/frequently-asked-questions/category/trophectoderm-biopsy-micropipette-bevelled.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-trophectoderm-biopsy-micropipette-bevelled-js" */),
  "component---src-pages-frequently-asked-questions-category-trophectoderm-biopsy-micropipette-flat-js": () => import("./../../../src/pages/frequently-asked-questions/category/trophectoderm-biopsy-micropipette-flat.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-trophectoderm-biopsy-micropipette-flat-js" */),
  "component---src-pages-frequently-asked-questions-category-zygote-intrafallopian-transfer-js": () => import("./../../../src/pages/frequently-asked-questions/category/zygote-intrafallopian-transfer.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-category-zygote-intrafallopian-transfer-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-holding-micropipette-js": () => import("./../../../src/pages/holding-micropipette.js" /* webpackChunkName: "component---src-pages-holding-micropipette-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-injection-micropipette-js": () => import("./../../../src/pages/injection-micropipette.js" /* webpackChunkName: "component---src-pages-injection-micropipette-js" */),
  "component---src-pages-mailchimp-js": () => import("./../../../src/pages/mailchimp.js" /* webpackChunkName: "component---src-pages-mailchimp-js" */),
  "component---src-pages-news-comp-1-js": () => import("./../../../src/pages/newsComp1.js" /* webpackChunkName: "component---src-pages-news-comp-1-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-polar-body-biopsy-micropipette-js": () => import("./../../../src/pages/polar-body-biopsy-micropipette.js" /* webpackChunkName: "component---src-pages-polar-body-biopsy-micropipette-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quality-pledge-js": () => import("./../../../src/pages/quality-pledge.js" /* webpackChunkName: "component---src-pages-quality-pledge-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-trophectoderm-biopsy-micropipette-bevelled-js": () => import("./../../../src/pages/trophectoderm-biopsy-micropipette-bevelled.js" /* webpackChunkName: "component---src-pages-trophectoderm-biopsy-micropipette-bevelled-js" */),
  "component---src-pages-trophectoderm-biopsy-micropipette-flat-js": () => import("./../../../src/pages/trophectoderm-biopsy-micropipette-flat.js" /* webpackChunkName: "component---src-pages-trophectoderm-biopsy-micropipette-flat-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-frequently-asked-questions-js": () => import("./../../../src/templates/frequently-asked-questions.js" /* webpackChunkName: "component---src-templates-frequently-asked-questions-js" */),
  "component---src-templates-news-and-updates-js": () => import("./../../../src/templates/news-and-updates.js" /* webpackChunkName: "component---src-templates-news-and-updates-js" */)
}

